import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SmartGoalsService {
  constructor(private http: HttpClient) {}

  public generateSmartGoals(goalsUserInput: string) {
    return this.http.post<string>(environment.apiUrl + '/smart-goals/', {
      user_input: goalsUserInput,
    });
  }

  public submitSmartGoals(email: string, goals: string) {
    return this.http.post<string>(environment.apiUrl + '/smart-goals/submit-goals/', {
      email,
      goals,
    });
  }
}
