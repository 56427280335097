import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DASHBOARD, RELATIONS } from 'src/app/app-routing.module';
import { CitiesLoad } from 'src/app/state/actions/cities.actions';
import { CountriesLoad } from 'src/app/state/actions/countries.actions';
import { EnvironmentsLoad } from 'src/app/state/actions/environments.actions';
import { FieldsLoad } from 'src/app/state/actions/fields.actions';
import { GenderLoad } from 'src/app/state/actions/gender.actions';
import { LanguagesLoad } from 'src/app/state/actions/languages.actions';
import { MenteeLoad, UpdateMentee } from 'src/app/state/actions/mentee.actions';
import { MentorLoad, UpdateMentor } from 'src/app/state/actions/mentor.actions';
import { PersonalAttributesLoad } from 'src/app/state/actions/personal-attributes.actions';
import { SkillsLoad } from 'src/app/state/actions/skills.actions';
import { TeamSizesLoad } from 'src/app/state/actions/team-sizes.actions';
import { AppState } from 'src/app/state/reducers';
import { getDirtyValues } from '../utils';
import { Mentor } from '../types/mentor.types';
import { UntypedFormGroup } from '@angular/forms';
import { Mentee } from '../types/mentee.types';
import { CompanyLoad, CompanyUpdate } from 'src/app/state/actions/company.actions';
import { Company } from '../types/company.types';
import { first } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { CoachLoad, CoachUpdate } from 'src/app/state/actions/coach.actions';
import { Coach } from '../types/coach.types';
import { StatusesLoad } from 'src/app/state/actions/statuses.actions';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(private store: Store<AppState>, private router: Router) {}

  loadData2Store(user_role: string) {
    localStorage.setItem('user_role', user_role);
    if (user_role == 'ME') {
      this.store.dispatch(new MenteeLoad());
    } else if (user_role == 'MR' || user_role == 'CH') {
      this.store.dispatch(new GenderLoad());
      this.store.dispatch(new LanguagesLoad());
      this.store.dispatch(new SkillsLoad());
      this.store.dispatch(StatusesLoad());
      if (user_role == 'MR') {
        this.store.dispatch(new MentorLoad());
        this.store.dispatch(new CitiesLoad());
        this.store.dispatch(new CountriesLoad());
        this.store.dispatch(new EnvironmentsLoad());
        this.store.dispatch(new FieldsLoad());
        this.store.dispatch(new PersonalAttributesLoad());
        this.store.dispatch(new TeamSizesLoad());
      } else if (user_role == 'CH') {
        this.store.dispatch(CoachLoad());
      }
    } else if (user_role == 'CC') {
      this.store.dispatch(new CompanyLoad());
    }
  }

  async navigateToRelation() {
    try {
      const item = await lastValueFrom(this.store.select(state => state.menteeRelations).pipe(first()));
      const mrs_len = Object.keys(item).length;
      if (item && mrs_len !== 0) {
        this.router.navigate([`../${RELATIONS}/${item[0].id}`]);
      } else {
        this.router.navigate([DASHBOARD]);
      }
    } catch (err) {
      this.router.navigate([DASHBOARD]);
    }
  }

  updateRawInstance(type: string, modelId: string, model: any) {
    if (type == 'mentee') {
      this.store.dispatch(new UpdateMentee(modelId, model));
    } else if (type === 'mentor') {
      this.store.dispatch(new UpdateMentor(modelId, model));
    } else if (type === 'coach') {
      this.store.dispatch(CoachUpdate({ coachId: modelId, coach: model }));
    }
  }

  updateDetails(type: string, modelId: string, form: UntypedFormGroup) {
    if (form.valid) {
      let dirtyValues = getDirtyValues(form);
      if (type === 'mentee') {
        const mentee: Partial<Mentee> = {
          ...dirtyValues,
        };
        this.store.dispatch(new UpdateMentee(modelId, mentee));
      } else if (type === 'mentor') {
        const mentor: Partial<Mentor> = {
          ...dirtyValues,
        };
        this.store.dispatch(new UpdateMentor(modelId, mentor));
      } else if (type === 'coach') {
        const coach: Partial<Coach> = {
          ...dirtyValues,
        };
        this.store.dispatch(CoachUpdate({ coachId: modelId, coach }));
      } else if (type === 'company') {
        const company: Partial<Company> = {
          ...dirtyValues,
        };
        this.store.dispatch(new CompanyUpdate(modelId, company));
      }
    }
  }
}
