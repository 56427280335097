import { Action } from '@ngrx/store';
import { Country } from 'src/app/shared/types/country.types';

export const COUNTRIES_LOAD = '[COUNTRY] Load countries';
export const COUNTRIES_LOAD_SUCCESS = '[COUNTRY] Load countries successful';
export const COUNTRIES_LOAD_ERROR = '[COUNTRY] Load countries failed';

export class CountriesLoad implements Action {
  readonly type = COUNTRIES_LOAD;
}

export class CountriesLoadSuccess implements Action {
  readonly type = COUNTRIES_LOAD_SUCCESS;
  constructor(public payload: Country[]) {}
}

export class CountriesLoadError implements Action {
  readonly type = COUNTRIES_LOAD_ERROR;
}

export type Actions = CountriesLoad | CountriesLoadSuccess | CountriesLoadError;
