import { Action } from '@ngrx/store';
import { Mentee } from 'src/app/shared/types/mentee.types';

export const MENTEE_SET_INITIAL = '[MENTEE] Set initial';
export const MENTEE_LOAD = '[MENTEE] Load mentee';
export const MENTEE_LOAD_SUCCESS = '[MENTEE] Load mentee successful';
export const MENTEE_LOAD_ERROR = '[MENTEE] Load mentee failed';
export const UPDATE_MENTEE = '[MENTEE] Update mentee';
export const UPDATE_MENTEE_SUCCESS = '[MENTEE] Update mentee successful';
export const UPDATE_MENTEE_ERROR = '[MENTEE] Update mentee failed';

export class MenteeSetInitial implements Action {
  readonly type = MENTEE_SET_INITIAL;
}

export class MenteeLoad implements Action {
  readonly type = MENTEE_LOAD;
}

export class MenteeLoadSuccess implements Action {
  readonly type = MENTEE_LOAD_SUCCESS;
  constructor(public payload: Mentee) {}
}

export class MenteeLoadError implements Action {
  readonly type = MENTEE_LOAD_ERROR;
}

export class UpdateMentee implements Action {
  readonly type = UPDATE_MENTEE;
  constructor(public menteeId: string, public mentee: Partial<Mentee>) {}
}

export class UpdateMenteeSuccess implements Action {
  readonly type = UPDATE_MENTEE_SUCCESS;
  constructor(public mentee: Mentee) {}
}

export class UpdateMenteeError implements Action {
  readonly type = UPDATE_MENTEE_ERROR;
  constructor(public id: string) {}
}

export type Actions =
  | MenteeSetInitial
  | MenteeLoad
  | MenteeLoadSuccess
  | MenteeLoadError
  | UpdateMentee
  | UpdateMenteeSuccess
  | UpdateMenteeError;
