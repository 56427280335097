import { Action } from '@ngrx/store';
import { Gender } from 'src/app/shared/types/gender.types';

export const GENDER_LOAD = '[GENDER] Load gender';
export const GENDER_LOAD_SUCCESS = '[GENDER] Load gender successful';
export const GENDER_LOAD_ERROR = '[GENDER] Load gender failed';

export class GenderLoad implements Action {
  readonly type = GENDER_LOAD;
}

export class GenderLoadSuccess implements Action {
  readonly type = GENDER_LOAD_SUCCESS;
  constructor(public payload: Gender[]) {}
}

export class GenderLoadError implements Action {
  readonly type = GENDER_LOAD_ERROR;
}

export type Actions = GenderLoad | GenderLoadSuccess | GenderLoadError;
