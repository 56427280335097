import { createSelector } from '@ngrx/store';
import { Conversation } from 'src/app/shared/types/conversation.types';
import { AppState } from '../reducers';

// Selector to get the conversation slice of state
export const selectConversationState = (state: AppState) => state.conversations;

// Selector to get the conversation by field
export const selectConversation = (field: string, submissionId: string) =>
  createSelector(selectConversationState, (allConversations: Conversation[]) => {
    if (allConversations) {
      return allConversations.find(
        (conversation: Conversation) =>
          conversation.field === field && conversation.resolved === false && conversation.submission === submissionId
      );
    }
  });
