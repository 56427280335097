import { Action } from '@ngrx/store';
import { User } from '../../shared/types/user.types';

export const UPDATE_USER = '[SETTINGS] Update user';
export const REMOVE_USER = '[SETTINGS] Remove user';

export class UpdateUser implements Action {
  readonly type = UPDATE_USER;
  constructor(public user: User) {}
}

export class RemoveUser implements Action {
  readonly type = REMOVE_USER;
  constructor() {}
}

export type Actions = UpdateUser | RemoveUser;
