import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailLog } from '../types/email-log.types';
import { environment } from 'src/environments/environment';
import { Session } from '../types/session.types';

@Injectable({
  providedIn: 'root',
})
export class EmailLogService {
  constructor(private http: HttpClient) {}

  public newEmailLogs(mr_id: string, email_type_ids: number[], default_model: string) {
    return this.http.post<EmailLog[]>(environment.apiUrl + '/email_log/new/', {
      mr_id,
      email_type_ids,
      default_model,
    });
  }
}
