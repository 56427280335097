import { Action } from '@ngrx/store';
import { Skill } from 'src/app/shared/types/skill.types';

export const SKILLS_LOAD = '[SKILL] Load skills';
export const SKILLS_LOAD_SUCCESS = '[SKILL] Load skills successful';
export const SKILLS_LOAD_ERROR = '[SKILL] Load skills failed';

export class SkillsLoad implements Action {
  readonly type = SKILLS_LOAD;
}

export class SkillsLoadSuccess implements Action {
  readonly type = SKILLS_LOAD_SUCCESS;
  constructor(public payload: Skill[]) {}
}

export class SkillsLoadError implements Action {
  readonly type = SKILLS_LOAD_ERROR;
}

export type Actions = SkillsLoad | SkillsLoadSuccess | SkillsLoadError;
