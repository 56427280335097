import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval, map, Subscription, take, throwError } from 'rxjs';
import { HeartBeatService } from 'src/app/shared/services/heartbeat.service';

@Component({
  selector: 'fp-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss'],
})
export class MaintenanceComponent implements OnInit {
  constructor(private heartBeatService: HeartBeatService, private router: Router) {}
  heartBeatSubscription: Subscription;

  ngOnInit() {
    // try every 3 minutes if server will respond
    const observable = interval(3 * 60 * 1000);
    this.heartBeatSubscription = observable
      .pipe(
        map(() => this.callHeartBeat()),
        take(1)
      )
      .subscribe({
        next: r => {
          this.callHeartBeat().subscribe(r => {
            if (r.status === 200) {
              this.heartBeatSubscription.unsubscribe();
              this.router.navigate([`auth`]);
            }
          });
        },
        error: err => {
          if (err instanceof HttpErrorResponse) {
            throwError(() => new Error('FP: Unable to Connect to the Server:' + err.message));
          }
        },
      });
  }
  callHeartBeat() {
    return this.heartBeatService.heartBeat();
  }
}
