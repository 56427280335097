import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OriginalUrlService {
  private originalUrl: string;

  setOriginalUrl(url: string) {
    this.originalUrl = url;
  }

  getOriginalUrl(): string {
    return this.originalUrl;
  }
}
