import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HeartBeatService {
  constructor(private http: HttpClient) {}

  /**
   * Check if endpoint is up
   * @returns http repsonse
   */
  public heartBeat() {
    return this.http.get<HttpResponse<any>>(environment.apiUrl + '/heart_beat/', { observe: 'response' });
  }
}
