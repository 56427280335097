import { Action } from '@ngrx/store';
import { City } from 'src/app/shared/types/city.types';

export const CITIES_LOAD = '[CITY] Load cities';
export const CITIES_LOAD_SUCCESS = '[CITY] Load cities successful';
export const CITIES_LOAD_ERROR = '[CITY] Load cities failed';

export class CitiesLoad implements Action {
  readonly type = CITIES_LOAD;
}

export class CitiesLoadSuccess implements Action {
  readonly type = CITIES_LOAD_SUCCESS;
  constructor(public payload: City[]) {}
}

export class CitiesLoadError implements Action {
  readonly type = CITIES_LOAD_ERROR;
}

export type Actions = CitiesLoad | CitiesLoadSuccess | CitiesLoadError;
