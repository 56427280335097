import { Action } from '@ngrx/store';
import { PersonalAttribute } from 'src/app/shared/types/personal-attribute.types';

export const PERSONALATTRIBUTES_LOAD = '[PERSONALATTRIBUTE] Load personal attributes';
export const PERSONALATTRIBUTES_LOAD_SUCCESS = '[PERSONALATTRIBUTE] Load personal attributes successful';
export const PERSONALATTRIBUTES_LOAD_ERROR = '[PERSONALATTRIBUTE] Load personal attributes failed';

export class PersonalAttributesLoad implements Action {
  readonly type = PERSONALATTRIBUTES_LOAD;
}

export class PersonalAttributesLoadSuccess implements Action {
  readonly type = PERSONALATTRIBUTES_LOAD_SUCCESS;
  constructor(public payload: PersonalAttribute[]) {}
}

export class PersonalAttributesLoadError implements Action {
  readonly type = PERSONALATTRIBUTES_LOAD_ERROR;
}

export type Actions = PersonalAttributesLoad | PersonalAttributesLoadSuccess | PersonalAttributesLoadError;
