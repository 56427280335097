import { Component, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MenteeRelation } from 'src/app/shared/types/mentee-relations.types';
import { Mentee } from 'src/app/shared/types/mentee.types';
import { Mentor } from 'src/app/shared/types/mentor.types';
import { UpdateMentee } from 'src/app/state/actions/mentee.actions';
import { AppState } from 'src/app/state/reducers';

@Component({
  selector: 'fp-goals-tracker',
  templateUrl: './goals-tracker.component.html',
  styleUrl: './goals-tracker.component.scss',
})
export class GoalsTrackerComponent implements OnChanges {
  constructor(
    private renderer: Renderer2,
    private store: Store<AppState>
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.userRole === 'ME') {
        if (this.goalsDoneForm.dirty) {
          this.goalsChange(this.goalsDoneForm);
        }
      }
    });
  }

  @Input() relation: MenteeRelation;
  @Input() role: Mentee | Mentor;
  @Input() source: string = '';
  @Input() userRole: string;

  goalsDoneForm: UntypedFormGroup;

  async ngOnChanges(changes: SimpleChanges) {
    if (this.userRole === 'ME') {
      this.goalsDoneForm = new FormGroup({
        goal1: new FormControl(this.role?.mentee_goal_1),
        goal2: new FormControl(this.role?.mentee_goal_2),
        goal3: new FormControl(this.role?.mentee_goal_3),
      });
    }
  }

  goalsChange(form: UntypedFormGroup) {
    if (form.valid && form.dirty) {
      var goals: Partial<Mentee> = { mentee_goal_1: form.value.goal1, mentee_goal_2: form.value.goal2, mentee_goal_3: form.value.goal3 };
      this.store.dispatch(new UpdateMentee(this.role.id, goals));
      form.markAsPristine();
    }
  }
}
