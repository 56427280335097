import { AfterViewChecked, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, Subscription, filter, first, lastValueFrom } from 'rxjs';
import { Comment } from 'src/app/shared/types/comment.types';
import { Conversation, ReadStatus } from 'src/app/shared/types/conversation.types';
import { hasValue } from 'src/app/shared/utils';
import { AddCommentToConversation, UpdateConversation } from 'src/app/state/actions/conversation.actions';
import { AppState } from 'src/app/state/reducers';
import { selectConversation } from 'src/app/state/selectors/conversation.selector';
import { MenteeRelation } from '../../types/mentee-relations.types';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { User } from '../../types/user.types';
import { NavigationExtras, Router } from '@angular/router';

@Component({
  selector: 'fp-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnChanges, OnDestroy, AfterViewChecked {
  constructor(
    private router: Router,
    private store: Store<AppState>
  ) {
    this.initialUrl = this.router.url;
  }

  faComment = faCommentDots;
  @ViewChild('bubbleIcon') bubbleIcon: ElementRef;
  @ViewChild('commentInput') commentInput: ElementRef;
  @ViewChild('overlayPanel') overlayPanel: OverlayPanel;
  @Input() field: string;
  @Input() open: string;
  @Input() relation: MenteeRelation;

  conversation: Partial<Conversation>;
  conversation$: Subscription;
  meUser;
  meUserLabel: string;
  otherUser;
  otherUserLabel: string;
  submissionId: string;
  unread: boolean;
  user: User;
  user$: Observable<User> = this.store.select(state => state.settings.user);
  user_role = localStorage.getItem('user_role');
  openChange: boolean = false;
  initialUrl: string;

  async ngOnChanges(changes) {
    this.user = await lastValueFrom(this.user$.pipe(filter(hasValue), first()));
    if (changes['relation']) {
      // use mentee as reference submission
      this.submissionId = this.relation?.mentee?.submission_id;
      if (this.user_role === 'ME') {
        this.meUser = this.relation?.mentee;
        this.otherUser = this.relation?.supporter;
      } else if (this.user_role !== 'ME') {
        this.meUser = this.relation?.supporter;
        this.otherUser = this.relation?.mentee;
      }
      this.meUserLabel = `${this.meUser?.first_name[0].toUpperCase()}${this.meUser?.last_name[0].toUpperCase()}`;
      this.otherUserLabel = `${this.otherUser?.first_name[0].toUpperCase()}${this.otherUser?.last_name[0].toUpperCase()}`;

      this.conversation$ = this.store.select(selectConversation(this.field, this.submissionId)).subscribe(data => {
        if (hasValue(data)) {
          this.conversation = cloneDeep(data);
          this.unread = this.isConversationUnread(data);
        } else {
          this.conversation = {
            submission: this.submissionId,
            field: this.field,
            notified_users: [{ id: this.meUser?.user_account }, { id: this.otherUser?.user_account }],
            mentee_relation: this.relation?.id,
          };
        }
      });
    }
    this.openChange = changes['open'] && this.open === this.field;

    // when another session added/removed
    if (changes['field']?.currentValue !== changes['field']?.previousValue) {
      this.conversation$ = this.store.select(selectConversation(this.field, this.submissionId)).subscribe(data => {
        if (hasValue(data)) {
          this.conversation = cloneDeep(data);
          this.unread = this.isConversationUnread(data);
        } else {
          this.conversation = {
            submission: this.submissionId,
            field: this.field,
            notified_users: [{ id: this.meUser?.user_account }, { id: this.otherUser?.user_account }],
            mentee_relation: this.relation?.id,
          };
        }
      });
    }
  }

  ngAfterViewChecked() {
    if (this.openChange) {
      setTimeout(() => {
        this.overlayPanel.show(new MouseEvent('click'), this.bubbleIcon.nativeElement);
        this.openChange = false;
      });
    }
  }

  toggle(event: Event) {
    this.overlayPanel.toggle(event);
  }

  isConversationUnread(conversation: Conversation): boolean {
    // Check if userReadStatus does not exist or if read is false
    const commentsArray = conversation.comments;
    let userLastCommented = null;
    if (commentsArray) {
      const last_comment = commentsArray[commentsArray.length - 1];
      userLastCommented = last_comment.created_by.id === this.user?.id;
    }
    const userReadStatus = conversation.read_by.find(status => {
      return status.user === this.user?.id;
    });
    return (!userReadStatus && !userLastCommented) || (userReadStatus && !userReadStatus.read);
  }

  readConversation() {
    if (this.conversation.read_by?.find(status => status.user === this.user.id && !status.read)) {
      const updateData: Partial<Conversation> = {
        read_by: [
          {
            user: this.user.id,
            read: true,
          },
        ] as ReadStatus[],
      };
      this.store.dispatch(new UpdateConversation(this.conversation.id, updateData));
    }
    let unreadConv;
    let storedUnreadConv = localStorage.getItem('unreadConv');
    if (storedUnreadConv) {
      unreadConv = JSON.parse(storedUnreadConv);
    } else {
      unreadConv = []; // Default value if nothing is stored
    }
    const index = unreadConv.indexOf(this.conversation.id);
    if (index !== -1) {
      unreadConv.splice(index, 1);
      localStorage.setItem('unreadConv', JSON.stringify(unreadConv));
    }
    this.bubbleIcon.nativeElement?.querySelector('span').classList.remove('p-badge-danger');
    this.bubbleIcon.nativeElement?.querySelector('span').classList.add('p-badge-success');
  }

  addComment(message: string) {
    if (message.length > 0) {
      const comment: Comment = {
        message,
        created_by: this.meUser,
      };
      if (!this.conversation.submission) {
        this.conversation.submission = this.submissionId;
      }
      this.store.dispatch(new AddCommentToConversation(this.conversation, comment));
      this.commentInput.nativeElement.value = '';
    }
  }

  removeQueryParams() {
    const navigationExtras: NavigationExtras = {
      queryParams: { openOverlay: null },
      queryParamsHandling: 'merge', // Remove existing query params
    };
    this.router.navigate([], navigationExtras);
  }

  ngOnDestroy() {
    if (this.router.url === this.initialUrl) {
      this.removeQueryParams();
    }
    if (this.conversation$) {
      this.conversation$.unsubscribe();
    }
  }
}
