import { Action, createAction, props } from '@ngrx/store';
import { MenteeRelation } from 'src/app/shared/types/mentee-relations.types';
import { AnyModelStr } from 'src/app/shared/types/model.types';

export const MENTEERELATION_SET_INITIAL = '[MENTEERELATION] Set initial';
export const MENTEERELATION_LOAD = '[MENTEERELATION] Load menteerelation';
export const MENTEERELATION_LOAD_SUCCESS = '[MENTEERELATION] Load menteerelation successful';
export const MENTEERELATION_LOAD_ERROR = '[MENTEERELATION] Load menteerelation failed';
export const UPDATE_MENTEERELATION = '[MENTEERELATION] Update menteerelation';
export const UPDATE_MENTEERELATION_SUCCESS = '[MENTEERELATION] Update menteerelation successful';
export const UPDATE_MENTEERELATION_ERROR = '[MENTEERELATION] Update menteerelation failed';

export const MenteeRelationAutoSave = createAction('Auto Save for MR', props<{ relation: Partial<MenteeRelation> }>());
export const STATE_MODIFYING_ACTIONS = [MenteeRelationAutoSave];

export class MenteeRelationSetInitial implements Action {
  readonly type = MENTEERELATION_SET_INITIAL;
}

export class MenteeRelationLoad implements Action {
  readonly type = MENTEERELATION_LOAD;
  constructor(public id: string, public role_type: AnyModelStr) {}
}

export class MenteeRelationLoadSuccess implements Action {
  readonly type = MENTEERELATION_LOAD_SUCCESS;
  constructor(public payload: MenteeRelation[]) {}
}

export class MenteeRelationLoadError implements Action {
  readonly type = MENTEERELATION_LOAD_ERROR;
}

export class UpdateMenteeRelation implements Action {
  readonly type = UPDATE_MENTEERELATION;
  constructor(public relation: Partial<MenteeRelation>) {}
}
export class UpdateMenteeRelationSuccess implements Action {
  readonly type = UPDATE_MENTEERELATION_SUCCESS;
  constructor(public payload: MenteeRelation) {}
}

export class UpdateMenteeRelationError implements Action {
  readonly type = UPDATE_MENTEERELATION_ERROR;
}

export type Actions =
  | MenteeRelationSetInitial
  | MenteeRelationLoad
  | MenteeRelationLoadSuccess
  | MenteeRelationLoadError
  | UpdateMenteeRelation
  | UpdateMenteeRelationSuccess
  | UpdateMenteeRelationError;
