import { Component, Input } from '@angular/core';
import { finalize } from 'rxjs';
import { AnyModelStr, ModelType } from '../../types/model.types';
import { NotificationService } from '../../services/notification.service';
import { UtilsService } from '../../services/utils.service';
import { FileService } from '../../services/file.service';

@Component({
  selector: 'fp-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent {
  @Input() model: ModelType;
  @Input() type: AnyModelStr;

  file: File = null;

  constructor(
    private fileService: FileService,
    private notificationService: NotificationService,
    public utilService: UtilsService
  ) {}

  onUpload(event) {
    this.notificationService.loading = true;
    this.file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', this.file);
    formData.append('submission_id', this.model.submission.id);
    formData.append('file_type', 'certification');
    this.fileService
      .uploadFile(formData)
      .pipe(finalize(() => (this.notificationService.loading = false)))
      .subscribe({
        next: res => {
          this.utilService.updateRawInstance(this.type, this.model.id, {
            certification_url: res['certification_url'],
          });

          this.notificationService.openSnackBar('success', 'New file has been saved');
          this.notificationService.loading = false;
        },
        error: err => {
          console.log(err);
        },
      });
  }

  openFile() {
    let url = this.model.certification_file;
    window.open(url, '_blank');
  }
}
