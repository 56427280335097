import { Action } from '@ngrx/store';
import { Language } from 'src/app/shared/types/language.types';

export const LANGUAGES_LOAD = '[LANGUAGE] Load languages';
export const LANGUAGES_LOAD_SUCCESS = '[LANGUAGE] Load languages successful';
export const LANGUAGES_LOAD_ERROR = '[LANGUAGE] Load languages failed';

export class LanguagesLoad implements Action {
  readonly type = LANGUAGES_LOAD;
}

export class LanguagesLoadSuccess implements Action {
  readonly type = LANGUAGES_LOAD_SUCCESS;
  constructor(public payload: Language[]) {}
}

export class LanguagesLoadError implements Action {
  readonly type = LANGUAGES_LOAD_ERROR;
}

export type Actions = LanguagesLoad | LanguagesLoadSuccess | LanguagesLoadError;
