<ng-container *ngIf="userRole === 'ME' && source !== 'tracker'">
  <h4 class="text-sm font-bold m-0 mb-3">Goals tracker</h4>
  <form [formGroup]="goalsDoneForm">
    <div class="flex flex-wrap gap-2">
      <div class="flex align-items-center">
        <label for="goal1" class="mr-1"> Goal 1 </label>
        <p-checkbox formControlName="goal1" [binary]="true" inputId="goal1" />
      </div>
      <div class="flex align-items-center">
        <label for="goal2" class="mr-1"> Goal 2 </label>
        <p-checkbox formControlName="goal2" [binary]="true" inputId="goal2" />
      </div>
      <div class="flex align-items-center">
        <label for="goal3" class="mr-1"> Goal 3 </label>
        <p-checkbox formControlName="goal3" [binary]="true" inputId="goal3" />
      </div>
    </div>
  </form>
  <div class="text-center">
    <button
      id="buttonTrackTasks"
      pButton
      type="button"
      label="Track your tasks"
      class="text-sm p-button-warning py-1 mt-3"
      [routerLink]="'../task-tracker'"
      data-test="track-tasks-button"
    ></button>
  </div>
</ng-container>

<ng-container *ngIf="userRole === 'MR'">
  <h4 class="text-sm font-bold m-0 lg:mb-5">Goals tracker</h4>
  <div class="flex flex-wrap gap-2 my-2 lg:mb-3 justify-content-center">
    <div class="flex align-items-center">
      <label for="goal1" class="mr-1"> Goal 1 </label>
      <p-checkbox [(ngModel)]="relation.mentee_goal_1" [binary]="true" readonly="true" inputId="goal1" class="cursor-default" />
    </div>
    <div class="flex align-items-center">
      <label for="goal2" class="mr-1"> Goal 2 </label>
      <p-checkbox [(ngModel)]="relation.mentee_goal_2" [binary]="true" readonly="true" inputId="goal2" class="cursor-default" />
    </div>
    <div class="flex align-items-center">
      <label for="goal3" class="mr-1"> Goal 3 </label>
      <p-checkbox [(ngModel)]="relation.mentee_goal_3" [binary]="true" readonly="true" inputId="goal3" class="cursor-default" />
    </div>
  </div>
</ng-container>

<ng-container *ngIf="source === 'tracker'">
  <div class="fix-height">
    <h4 class="text-sm font-bold m-0 mb-3">Goals tracker</h4>
    <form [formGroup]="goalsDoneForm">
      <div class="flex flex-column flex-wrap gap-2 align-items-center text-xs">
        <div class="flex align-items-center">
          <label for="goal1" class="mr-2"> Goal 1 </label>
          <p-checkbox formControlName="goal1" [binary]="true" inputId="goal1" />
        </div>
        <div class="flex align-items-center">
          <label for="goal2" class="mr-2"> Goal 2 </label>
          <p-checkbox formControlName="goal2" [binary]="true" inputId="goal2" />
        </div>
        <div class="flex align-items-center">
          <label for="goal3" class="mr-2"> Goal 3 </label>
          <p-checkbox formControlName="goal3" [binary]="true" inputId="goal3" />
        </div>
      </div>
    </form>
  </div>
</ng-container>
