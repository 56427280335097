import { Action } from '@ngrx/store';
import { Environment } from 'src/app/shared/types/environment.types';

export const ENVIRONMENTS_LOAD = '[ENVIRONMENT] Load environments';
export const ENVIRONMENTS_LOAD_SUCCESS = '[ENVIRONMENT] Load environments successful';
export const ENVIRONMENTS_LOAD_ERROR = '[ENVIRONMENT] Load environments failed';

export class EnvironmentsLoad implements Action {
  readonly type = ENVIRONMENTS_LOAD;
}

export class EnvironmentsLoadSuccess implements Action {
  readonly type = ENVIRONMENTS_LOAD_SUCCESS;
  constructor(public payload: Environment[]) {}
}

export class EnvironmentsLoadError implements Action {
  readonly type = ENVIRONMENTS_LOAD_ERROR;
}

export type Actions = EnvironmentsLoad | EnvironmentsLoadSuccess | EnvironmentsLoadError;
