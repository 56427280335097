import { createAction, props } from '@ngrx/store';
import { Coach } from 'src/app/shared/types/coach.types';

export const CoachSetInitial = createAction('[COACH] Set initial');
export const CoachLoad = createAction('[COACH] Load coach');
export const CoachLoadSuccess = createAction('[COACH] Load coach successful', props<{ coach: Coach }>());
export const CoachLoadError = createAction('[COACH] Load coach failed');
export const CoachUpdate = createAction('[COACH] Update coach', props<{ coachId: string; coach: Partial<Coach> }>());
export const CoachUpdateSuccess = createAction('[COACH] Update coach successful', props<{ coach: Coach }>());
export const CoachUpdateError = createAction('[COACH] Update coach failed');

export type Actions =
  | ReturnType<typeof CoachSetInitial>
  | ReturnType<typeof CoachLoad>
  | ReturnType<typeof CoachLoadSuccess>
  | ReturnType<typeof CoachLoadError>
  | ReturnType<typeof CoachUpdate>
  | ReturnType<typeof CoachUpdateSuccess>
  | ReturnType<typeof CoachUpdateError>;
