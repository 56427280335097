<!-- icon visible anywhere in design -->
<ng-container *ngIf="this.otherUser?.user_account; else noCounterpart">
  <ng-container *ngIf="this.conversation?.comments?.length > 0">
    <span #bubbleIcon pBadge [severity]="unread ? 'danger' : 'success'">
      <fa-icon [icon]="faComment" (click)="toggle($event)" class="ml-2"></fa-icon
    ></span>
  </ng-container>
  <ng-container *ngIf="this.conversation?.comments?.length === 0 || !this.conversation?.comments">
    <fa-icon #bubbleIcon [icon]="faComment" (click)="toggle($event)" class="ml-2"></fa-icon>
  </ng-container>
</ng-container>

<ng-template #noCounterpart>
  <fa-icon
    #bubbleIcon
    [icon]="faComment"
    class="ml-2 disabled"
    pTooltip="Ask your partner to create an account on the mentoring platform to be able to use this feature."
  ></fa-icon>
</ng-template>
<!-- conversation overlay -->
<p-overlayPanel
  [dismissable]="true"
  [showCloseIcon]="true"
  #overlayPanel
  styleClass="comment"
  [appendTo]="'body'"
  (onShow)="readConversation()"
>
  <ng-template pTemplate>
    <div class="flex justify-content-center align-items-center mb-3 border-bottom">
      <p-avatar
        #avatar
        [image]="meUser?.photo ? meUser.photo : undefined"
        [label]="!meUser?.photo ? meUserLabel : undefined"
        styleClass="avatar group-avatar"
        shape="circle"
        onselectstart="false"
        class="fix-height text-sm"
      ></p-avatar>
      <p-avatar
        #avatar
        [image]="otherUser?.photo ? otherUser.photo : undefined"
        [label]="!otherUser?.photo ? otherUserLabel : undefined"
        styleClass="avatar group-avatar second-avatar"
        shape="circle"
        onselectstart="false"
        class="fix-height text-sm"
      ></p-avatar>
      <span class="font-bold fix-height">Start a conversation</span>
    </div>
    <div *ngIf="conversation?.comments" class="flex flex-column">
      <div *ngFor="let comment of conversation?.comments" class="mb-1 flex">
        <p-avatar
          *ngIf="comment.created_by.id !== meUser.user_account"
          [image]="otherUser?.photo ? otherUser.photo : undefined"
          [label]="!otherUser?.photo ? otherUserLabel : undefined"
          styleClass="avatar commenter-avatar"
          shape="circle"
          onselectstart="false"
          class="text-sm"
        ></p-avatar>
        <div class="flex flex-column w-full pl-1">
          <div class="message-timestamp">
            {{ comment?.created_at | date : 'dd.MM.yyyy' }}
          </div>
          <div
            class="message"
            [ngClass]="{
              'message-grey': comment.created_by.id === meUser.user_account,
              'message-orange': comment.created_by.id !== meUser.user_account
            }"
          >
            <p class="message-content">{{ comment.message }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="p-inputgroup" [style]="{ position: 'relative' }" [class]="conversation?.comments ? 'mb-0' : 'mb-3'">
      <textarea
        [autofocus]="true"
        #commentInput
        [rows]="3"
        [autoResize]="true"
        type="text"
        pInputTextarea
        placeholder="Start typing.."
        class="text-xs"
      ></textarea>
      <button
        tabindex="-1"
        (click)="addComment(commentInput.value)"
        type="button"
        pButton
        pRipple
        class="p-button-success"
      >
        <fa-icon [icon]="faComment"></fa-icon>
      </button>
    </div>
  </ng-template>
</p-overlayPanel>
