<p-dialog [(visible)]="dialogDisplay" [modal]="true" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '40vw' }">
  <div class="flex flex-column m-auto md:mx-6 text-center">
    <h2 class="text-center text-20">Share the SMART goals with us</h2>
    <div class="mt-2 mb-4 text-sm">We need your email to match it with your application.</div>
    <div class="min-w-20rem align-self-center">
      <input pInputText placeholder="Your email address*" class="w-full text-14" resize="none" id="email" [formControl]="email" />
      <p class="text-xxs text-left pt-1 pl-2">*Use the same email you used in the previous step of the application</p>
    </div>
    <div class="block text-center">
      <p-button
        *ngIf="!loadingSubmit"
        (click)="submitSmartGoals()"
        [disabled]="email.invalid || loadingSubmit"
        styleClass="p-button-success text-sm my-4 p-3 w-12rem"
        label="Submit your SMART goals"
        type="submit"
      >
      </p-button>
      <p-button
        *ngIf="loadingSubmit"
        styleClass="p-button-success text-sm my-4 p-3 w-12rem inline-block"
        type="button"
        [disabled]="email.invalid || loadingSubmit"
        ><i class="pi pi-spinner pi-spin"> </i> &nbsp; Submiting
      </p-button>
      <p class="text-xs max-w-17rem m-auto">Don’t worry. You can always change your goal during the interview with the program manager!</p>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="innerDialogDisplay" [modal]="true" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }" [style]="{ width: '40vw' }">
  <div class="flex flex-column m-auto md:mx-6 align-items-center text-center">
    <h2 class="text-20 max-w-17rem">We saved the goals! You can find them in the Mentoring tab.</h2>
    <div class="mt-2 mb-3 text-sm line-height-2">
      Your mentor will not be automatically notified of this change. Please use this button to inform them, or discuss it during your next
      call.
    </div>
    <div class="block text-center">
      <p-button
        *ngIf="!loadingSubmit"
        (click)="notifyMentor()"
        [disabled]="loadingSubmit"
        styleClass="p-button-warning text-sm my-4 p-3 w-12rem"
        label="Notify mentor"
        type="submit"
      >
      </p-button>
      <p-button
        *ngIf="loadingSubmit"
        styleClass="p-button-warning text-sm my-4 p-3 w-12rem inline-block"
        type="button"
        [disabled]="loadingSubmit"
        ><i class="pi pi-spinner pi-spin"> </i> &nbsp; Notifying
      </p-button>
    </div>
  </div>
</p-dialog>

<div *ngIf="!goalsSubmited; else submitedPage">
  <h1 *ngIf="source !== 'embed'" class="text-20 mb-5">Let us help you define your goals for mentoring!</h1>
  <h1 *ngIf="source === 'embed'" class="mb-5">Define your goals for mentoring!</h1>

  <p *ngIf="source === 'inner'" class="text-sm max-width-line">
    Do you need to improve your mentoring goals? We have created a tool with the help of AI that will assist you in defining your goals for
    the program. Keep in mind, however, that while this tool can assist in refining your goals, it's crucial to remain consistent with the
    goals established during the application process.
  </p>

  <p *ngIf="source === 'public'" class="text-sm max-width-line">
    For successful matching, please create your SMART goals. We have created a tool with the help of AI that will assist you in defining
    your goals for the program. You will then discuss these goals during the interview with the Program Manager. If you are not ready and
    need to talk to the Program Manager first, feel free to skip this step.
  </p>
  <div *ngIf="source === 'embed'">
    <p class="text-sm max-width-line">
      For successful mentoring it’s important to set the right SMART goals. We created a tool (with help of AI), that will help you define
      your goals for mentoring.
    </p>
    <div class="pt-3">
      <p class="font-bold text-14 pb-2">Example what to submit:</p>
      <ol class="text-14 mt-0 example pl-3">
        <li>Become a better leader</li>
        <li>Learn how to communicate with my team better</li>
        <li>Be better at delegating tasks</li>
      </ol>
    </div>
  </div>

  <h2 class="text-20 line-height-2 mt-6">
    What would you like to focus on in the program? Describe 1-3 areas you’d like to concentrate on.
  </h2>
  <form>
    <div class="grid">
      <div [ngClass]="{ 'col-12': true, 'lg:col-8': source !== 'embed' }">
        <textarea
          id="goalsUserInput"
          [formControl]="goalsUserInput"
          pInputTextarea
          [autoResize]="true"
          rows="8"
          class="text-14 w-full"
          [placeholder]="
            source === 'embed'
              ? 'Write what you’d like to achieve and focus on in the next 6 months..'
              : 'Write 1-3 general goals that you would like to achieve and focus on in the next 6 months.'
          "
        ></textarea>
        <div *ngIf="goalsUserInput.invalid && (goalsUserInput.dirty || goalsUserInput.touched) && showErrorBox" class="error-box">
          <div *ngIf="goalsUserInput.hasError('required')">Please insert areas you’d like to focus on.</div>
          <div *ngIf="goalsUserInput.hasError('minlength')">Could you please give us a more detailed definition?</div>
        </div>
        <div *ngIf="requestFailed" class="error-box">We're sorry, but this service is currently unavailable. Please try again later.</div>
        <div *ngIf="requestInvalid" class="error-box">
          Please note that this tool is designed to utilize OpenAI technology exclusively for creating SMART goals from given areas. It is
          not permitted to use this tool for any other purposes.
        </div>
      </div>
      <div *ngIf="source !== 'embed'" class="col-12 lg:col-4">
        <div class="lg:pl-5 pt-3">
          <p class="font-bold text-14">Example what to submit:</p>
          <ol class="text-14 mt-0 example">
            <li>Become a better leader</li>
            <li>Learn how to communicate with my team better</li>
            <li>Be better at delegating tasks</li>
          </ol>
        </div>
      </div>
    </div>
    <p-button
      *ngIf="!loadingGenerate"
      (click)="generateSmartGoals()"
      styleClass="p-button-warning text-sm mt-3 p-3 w-12rem  line-height-2"
      label="Generate SMART goals"
      type="button"
      [disabled]="goalsUserInput.invalid || loadingGenerate"
    >
    </p-button>
    <p-button
      *ngIf="loadingGenerate"
      styleClass="p-button-warning text-sm mt-3 p-3 w-12rem inline-block line-height-2"
      type="button"
      [disabled]="goalsUserInput.invalid || loadingGenerate"
      ><i class="pi pi-spinner pi-spin"> </i> &nbsp; Generating
    </p-button>
  </form>

  <h2 class="text-20 line-height-2 mt-6">Suggested SMART goals for you..</h2>
  <form>
    <div class="grid">
      <div [ngClass]="{ 'col-12': true, 'lg:col-8': source !== 'embed' }">
        <textarea
          id="generatedGoals"
          [formControl]="generatedGoals"
          pInputTextarea
          [autoResize]="true"
          rows="8"
          [hidden]="showEditor"
          class="text-14 w-full"
        >
        </textarea>
        <p-editor id="generatedGoals" [formControl]="generatedGoals" [hidden]="!showEditor" class="text-14 w-full">
          <ng-template pTemplate="header">
            <span class="ql-formats">
              <button type="button" class="ql-bold" aria-label="Bold"></button>
              <button type="button" class="ql-italic" aria-label="Italic"></button>
              <button type="button" class="ql-underline" aria-label="Underline"></button>
              <button type="button" class="ql-list" value="ordered" aria-label="Ordered list"></button>
              <button type="button" class="ql-list" value="bullet" aria-label="Bullet list"></button>
            </span>
          </ng-template>
        </p-editor>
        <div *ngIf="generatedGoals.invalid && (generatedGoals.dirty || generatedGoals.touched) && showErrorBox" class="error-box">
          <div *ngIf="generatedGoals.hasError('required')">Please insert your SMART goals.</div>
          <div *ngIf="generatedGoals.hasError('minlength')">Could you please give us a more detailed definition?</div>
        </div>
        <p class="text-14 line-height-2 my-3">
          Not what you wanted? Generate new ideas until you are happy. Feel free to edit them as well. If you generate new goals, the
          previous ones will disappear. Having troubles? Report the bug
          <a href="https://femmepalette.typeform.com/to/rdzFtw4D?typeform-source=www.google.com" target="_blank" class="underline">here</a>.
        </p>
        <p *ngIf="source === 'public'" class="text-16">When you’re happy with it, submit the SMART goals to us.</p>
      </div>
    </div>
    <ng-container *ngIf="source !== 'embed'">
      <p-button
        *ngIf="source === 'inner'"
        (click)="saveInnerGoals()"
        [disabled]="generatedGoals.invalid"
        styleClass="p-button-success text-sm mt-2 p-3"
        label="Save the SMART goals"
        type="submit"
      >
      </p-button>
      <p-button
        *ngIf="source === 'public'"
        (click)="generatedGoals.valid ? (dialogDisplay = true) : (dialogDisplay = false)"
        [disabled]="generatedGoals.invalid"
        styleClass="p-button-success text-sm mt-2 p-3"
        label="Submit your SMART goals"
        type="submit"
      >
      </p-button
    ></ng-container>
  </form>
</div>

<ng-template #submitedPage>
  <div class="text-center flex flex-column justify-content-center mt-15vh">
    <h1 class="text-25">We received your SMART goals!</h1>
    <p class="text-20 my-4">You will discuss them on the onboarding call with the program manager.</p>
    <p-button (click)="goalsSubmited = false" styleClass="p-button-warning text-sm mt-2 p-3" label="Submit the goals again" type="button">
    </p-button>
  </div>
</ng-template>
