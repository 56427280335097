<div
  class="card flex flex-column lg:flex-row align-items-stretch justify-content-center p-1 border-1 border-transparent"
  [class]="!displayEditorGoals ? 'infoText' : 'none'"
>
  <div class="align-self-center px-3 py-1 font-bold">Your goals for mentoring</div>
  <form [formGroup]="goalsForm" class="relative flex-1 text-sm w-full h-full m-0" (keyup)="goalsChange(goalsForm)">
    <ng-container *ngIf="relation?.supporter.type === 'mentor'">
      <div class="absolute right-0">
        <button
          #shareGoalsBtn
          *ngIf="!displayEditorGoals && relation?.mr_status === 10"
          pButton
          type="button"
          label="Notify"
          [disabled]="!goalsForm.value.goals"
          (click)="shareGoals()"
          class="p-button-success text-xs py-1"
          [pTooltip]="
            !this.shareGoalsBtn.disabled ? 'Notify your mentor when you changed your goals. They will be notified in the platform.' : null
          "
          tooltipPosition="bottom"
          data-umami-event="Notify edited goals"
        ></button>
        <button
          *ngIf="!displayEditorGoals"
          pButton
          type="button"
          [routerLink]="'../' + INNERSMARTGOALSGENERATOR"
          [disabled]="!goalsForm.value.goals"
          class="p-button-redirect text-xs p-1 ml-1"
          pTooltip="Generate your SMART goals"
          tooltipPosition="bottom"
          data-umami-event="Use smart goals generator"
        >
          <fa-icon #icon [icon]="faWand"></fa-icon>
        </button>
      </div>
      <div
        #infoGoals
        [style.display]="!hideInfoGoals ? 'flex' : 'none'"
        class="fix-height-list text-sm flex-1 h-full mt-4 flex-column"
        [class]="!goalsForm.value.goals ? 'font-italic placeholder-padding' : null"
        (click)="showEditorGoals()"
        [innerHTML]="goalsForm.value.goals ? (goalsForm.value.goals | safeHTML) : 'Start typing..'"
      ></div>
    </ng-container>

    <div
      *ngIf="relation?.supporter.type === 'coach'"
      #infoGoals
      [style.display]="!hideInfoGoals ? 'flex' : 'none'"
      class="fix-height-list text-sm flex-1 flex-column h-full"
      [class]="!goalsForm.value.goals ? 'font-italic align-items-center' : null"
      (click)="showEditorGoals()"
      [innerHTML]="
        goalsForm.value.goals ? (goalsForm.value.goals | safeHTML) : 'Add your goals after you define the SMART goals with your coach.'
      "
    ></div>
    <p-editor
      #editorGoals
      [style.display]="displayEditorGoals ? 'block' : 'none'"
      formControlName="goals"
      placeholder="Start typing.."
      class="w-full my-2"
    >
      <ng-template pTemplate="header">
        <span class="ql-formats">
          <button type="button" class="ql-bold" aria-label="Bold"></button>
          <button type="button" class="ql-italic" aria-label="Italic"></button>
          <button type="button" class="ql-underline" aria-label="Underline"></button>
          <button type="button" class="ql-list" value="ordered" aria-label="Ordered list"></button>
          <button type="button" class="ql-list" value="bullet" aria-label="Bullet list"></button>
        </span>
      </ng-template>
    </p-editor>
  </form>
</div>
