import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { ImageCroppedEvent, ImageCropperComponent, ImageTransform } from 'ngx-image-cropper';
import { firstValueFrom } from 'rxjs';
import { FileService } from 'src/app/shared/services/file.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ModelType } from 'src/app/shared/types/model.types';

@Component({
  selector: 'fp-picture-cropper',
  templateUrl: './picture-cropper.component.html',
  styleUrls: ['./picture-cropper.component.scss'],
})
export class PictureCropperComponent implements OnChanges {
  constructor(
    private fileService: FileService,
    private notificationService: NotificationService,
    public utilService: UtilsService
  ) {}
  @Input() type: string;
  @Input() model: ModelType;
  @ViewChild('imgCropper') imgCropper: ImageCropperComponent;
  @ViewChild('badgePreviewElement') badgePreviewElement: HTMLImageElement;
  modelReady = false;
  photoPreview: any;
  drawingBadge = false;
  loading = true;
  loadingBtn = false;
  loadingRemoveBtn = false;
  display: boolean = false;
  displayPreview: boolean = false;
  badgePreviewURL: string;
  badgePreviewLoaded: boolean = false;
  imageBase64: any;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  transform: ImageTransform = {};
  fileName: string;
  badgeSrc: string;

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['model'].currentValue) {
      this.modelReady = true;
      if (this.model.photo) {
        const data = await firstValueFrom(this.fileService.getPhoto(this.model.submission.id));
        this.photoPreview = data;
      }
    }
  }

  async showDialog() {
    this.display = true;
    if (this.model.photo) {
      const data = await firstValueFrom(this.fileService.getPhoto(this.model.submission.id));
      this.imageBase64 = data; // cannot be moved to onChanges (don't know why..)
      this.loading = false;
    } else {
      this.loading = false;
    }
  }

  uploadCroppedImage() {
    this.notificationService.loading = true;
    this.loadingBtn = true;
    this.fileService.uploadImage(this.croppedImage, this.model.submission.id).subscribe({
      next: data => {
        this.display = false;
        this.photoPreview = data;
        this.utilService.updateRawInstance(this.type, this.model.id, { photo_url: data['photo_url'] });
      },
      error: err => {
        if (err) {
          this.notificationService.openSnackBar('error', 'The new image cannot be saved, please contact our support.');
          this.notificationService.loading = false;
          this.loadingBtn = false;
        }
      },
      complete: () => {
        this.notificationService.loading = false;
        this.loadingBtn = false;
        this.resetImage();
      },
    });
  }

  removeImage() {
    this.notificationService.loading = true;
    this.loadingRemoveBtn = true;
    this.fileService.removeImage(this.model.submission.id).subscribe({
      next: async data => {
        this.display = false;
        this.photoPreview = undefined;
        this.imageBase64 = undefined;
        this.fileName = undefined;
        this.croppedImage = undefined;
        this.utilService.updateRawInstance(this.type, this.model.id, { photo_url: undefined });
      },
      error: err => {
        if (err) {
          this.notificationService.openSnackBar('error', 'The image cannot be removed, please contact our support.');
          this.notificationService.loading = false;
          this.loadingRemoveBtn = false;
        }
      },
      complete: () => {
        this.notificationService.loading = false;
        this.loadingRemoveBtn = false;
      },
    });
  }

  getBadge(badgeSource: string) {
    this.displayPreview = true;
    this.badgeSrc = badgeSource;
  }

  previewBadgeLoaded() {
    this.badgePreviewElement.hidden = false;
    this.badgePreviewLoaded = true;
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.fileName = event.target.files[0].name;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    this.showCropper = true;
  }

  cropperReady() {}

  loadImageFailed() {
    this.notificationService.openSnackBar('error', 'Loading of image has failed');
  }

  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH,
    };
  }

  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH,
    };
  }

  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV,
    };
  }

  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }
}
