import { Component } from '@angular/core';

@Component({
  selector: 'fp-farewell',
  templateUrl: './farewell.component.html',
  styleUrls: ['./farewell.component.scss'],
})
export class FarewellComponent {
  constructor() {}
}
