import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/reducers';
import { SETTINGS, COMPANY, DASHBOARD } from 'src/app/app-routing.module';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Subscription, filter, first } from 'rxjs';
import { hasValue } from 'src/app/shared/utils';

@Component({
  selector: 'fp-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  constructor(
    private authService: AuthenticationService,
    private messageService: MessageService,
    private store: Store<AppState>,
    public notificationCenter: NotificationService
  ) {}

  COMPANY = COMPANY;
  DASHBOARD = DASHBOARD;
  displayConversations = false;
  label: string;
  logo;
  public menuItems: MenuItem[];
  modelSubscription: Subscription;
  photo;
  photoLoading: boolean = true;
  @Input() user_role: string;
  user$ = this.store.select(state => state.settings.user);

  ngOnInit() {
    switch (this.user_role) {
      case 'ME':
        this.updateUserRoleData('mentee');
        break;
      case 'MR':
        this.updateUserRoleData('mentor');
        break;
      case 'CH':
        this.updateUserRoleData('coach');
        break;
      case 'CC':
        this.modelSubscription = this.store
          .select(state => state.company)
          .subscribe(company => {
            this.label = company?.name.slice(0, 2).toUpperCase();
            this.photoLoading = false;
            if (company?.logo) {
              this.logo = company.logo;
            }
          });
        break;
    }

    this.menuItems = [
      { label: 'Settings', icon: 'pi pi-cog', routerLink: SETTINGS },
      { label: 'Log out', icon: 'pi pi-sign-out', command: () => this.signOut() },
    ];
  }

  updateUserRoleData(role) {
    const user$ = this.store.select(state => state[role]);

    // Handle the first emission and execute the "photoLoading" logic
    user$.pipe(filter(hasValue), first()).subscribe({
      next: user => {
        if (user?.photo) {
          this.photo = user.photo;
        } else {
          this.photo = undefined;
          this.label = `${user?.first_name[0]}${user?.last_name[0]}`.toUpperCase();
        }
        this.photoLoading = false;
      },
    });

    // Continue to subscribe for further updates
    this.modelSubscription = user$.subscribe({
      next: user => {
        if (user?.photo) {
          this.photo = user.photo;
        } else {
          this.photo = undefined;
          this.label = `${user?.first_name[0]}${user?.last_name[0]}`.toUpperCase();
        }
      },
    });
  }

  onConfirm() {
    this.messageService.clear('c');
  }

  onReject() {
    this.messageService.clear('c');
  }

  clear() {
    this.messageService.clear();
  }

  signOut() {
    this.authService.signOut();
  }

  toggleConversations() {
    this.displayConversations = !this.displayConversations;
  }

  getUnreadConvFromLocalStorage(): string[] {
    const unreadConvString = localStorage.getItem('unreadConv');
    return unreadConvString ? JSON.parse(unreadConvString) : [];
  }

  ngOnDestroy() {
    this.modelSubscription?.unsubscribe();
  }
}
