import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: HttpClient) {}

  public getPhoto(id: string) {
    return this.http.post(environment.apiUrl + '/model/get_photo/', {
      submission_id: id,
    });
  }

  public uploadImage(img: any, submissionId: string) {
    return this.http.post(environment.apiUrl + '/shared/upload_img/', {
      img,
      submission_id: submissionId,
    });
  }

  public removeImage(submissionId: string) {
    return this.http.post(environment.apiUrl + '/shared/remove_img/', {
      submission_id: submissionId,
    });
  }

  public draw_badge(submissionId: string) {
    return this.http.post<string>(environment.apiUrl + `/shared/badge/draw/`, { submission_id: submissionId });
  }

  public uploadFile(formData) {
    return this.http.post(environment.apiUrl + '/shared/upload_file/', formData);
  }

  downloadImage(imageUrl: string): Observable<Blob> {
    return this.http.get(imageUrl, { responseType: 'blob' });
  }
}
