import { Action } from '@ngrx/store';
import { Mentor } from 'src/app/shared/types/mentor.types';

export const MENTOR_SET_INITIAL = '[MENTOR] Set initial';
export const MENTOR_LOAD = '[MENTOR] Load mentor';
export const MENTOR_LOAD_SUCCESS = '[MENTOR] Load mentor successful';
export const MENTOR_LOAD_ERROR = '[MENTOR] Load mentor failed';
export const UPDATE_MENTOR = '[MENTOR] Update mentor';
export const UPDATE_MENTOR_SUCCESS = '[MENTOR] Update mentor successful';
export const UPDATE_MENTOR_ERROR = '[MENTOR] Update mentor failed';

export class MentorSetInitial implements Action {
  readonly type = MENTOR_SET_INITIAL;
}

export class MentorLoad implements Action {
  readonly type = MENTOR_LOAD;
}

export class MentorLoadSuccess implements Action {
  readonly type = MENTOR_LOAD_SUCCESS;
  constructor(public payload: Mentor) {}
}

export class MentorLoadError implements Action {
  readonly type = MENTOR_LOAD_ERROR;
}

export class UpdateMentor implements Action {
  readonly type = UPDATE_MENTOR;
  constructor(public mentorId: string, public mentor: Partial<Mentor>) {}
}

export class UpdateMentorSuccess implements Action {
  readonly type = UPDATE_MENTOR_SUCCESS;
  constructor(public mentor: Mentor) {}
}

export class UpdateMentorError implements Action {
  readonly type = UPDATE_MENTOR_ERROR;
  constructor(public id: string) {}
}

export type Actions =
  | MentorSetInitial
  | MentorLoad
  | MentorLoadSuccess
  | MentorLoadError
  | UpdateMentor
  | UpdateMentorSuccess
  | UpdateMentorError;
