import { Action } from '@ngrx/store';
import { Field } from 'src/app/shared/types/field.types';

export const FIELDS_LOAD = '[FIELD] Load fields';
export const FIELDS_LOAD_SUCCESS = '[FIELD] Load fields successful';
export const FIELDS_LOAD_ERROR = '[FIELD] Load fields failed';

export class FieldsLoad implements Action {
  readonly type = FIELDS_LOAD;
}

export class FieldsLoadSuccess implements Action {
  readonly type = FIELDS_LOAD_SUCCESS;
  constructor(public payload: Field[]) {}
}

export class FieldsLoadError implements Action {
  readonly type = FIELDS_LOAD_ERROR;
}

export type Actions = FieldsLoad | FieldsLoadSuccess | FieldsLoadError;
