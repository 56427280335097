import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  loading = false;

  constructor(private messageService: MessageService, private http: HttpClient) {}

  openSnackBar(severity: 'success' | 'warn' | 'error', detail: string, sticky = false) {
    const messageSeverityMap = { success: 'Success', warn: 'Warning', error: 'Error' };
    this.messageService.add({ severity, summary: messageSeverityMap[severity], detail: detail, sticky: sticky });
  }
}
