import { Action } from '@ngrx/store';
import { Comment } from 'src/app/shared/types/comment.types';
import { Conversation } from 'src/app/shared/types/conversation.types';

export const CONVERSATION_SET_INITIAL = '[CONVERSATION] Set initial';
export const CONVERSATION_LOAD = '[CONVERSATION] Load conversation';
export const CONVERSATION_LOAD_SUCCESS = '[CONVERSATION] Load conversation successful';
export const CONVERSATION_LOAD_ERROR = '[CONVERSATION] Load conversation failed';
export const ADD_COMMENT_TO_CONVERSATION = '[CONVERSATION] Add comment to conversation';
export const ADD_COMMENT_TO_CONVERSATION_SUCCESS = '[CONVERSATION] Add comment to conversation successful';
export const ADD_COMMENT_TO_CONVERSATION_ERROR = '[CONVERSATION] Add comment to conversation failed';
export const UPDATE_CONVERSATION = '[CONVERSATION] Update conversation';
export const UPDATE_CONVERSATION_SUCCESS = '[CONVERSATION] Update conversation successful';
export const UPDATE_CONVERSATION_ERROR = '[CONVERSATION] Update conversation failed';

export class ConversationSetInitial implements Action {
  readonly type = CONVERSATION_SET_INITIAL;
}

export class ConversationLoad implements Action {
  readonly type = CONVERSATION_LOAD;
}

export class ConversationLoadSuccess implements Action {
  readonly type = CONVERSATION_LOAD_SUCCESS;
  constructor(public payload: Conversation[]) {}
}

export class ConversationLoadError implements Action {
  readonly type = CONVERSATION_LOAD_ERROR;
}

export class AddCommentToConversation implements Action {
  readonly type = ADD_COMMENT_TO_CONVERSATION;
  constructor(public conversation: Partial<Conversation>, public comment: Comment) {}
}

export class AddCommentToConversationSuccess implements Action {
  readonly type = ADD_COMMENT_TO_CONVERSATION_SUCCESS;
  constructor(public payload: Conversation) {}
}

export class AddCommentToConversationError implements Action {
  readonly type = ADD_COMMENT_TO_CONVERSATION_ERROR;
  constructor(public id: string) {}
}

export class UpdateConversation implements Action {
  readonly type = UPDATE_CONVERSATION;
  constructor(public conversationId: string, public conversation: Partial<Conversation>) {}
}

export class UpdateConversationSuccess implements Action {
  readonly type = UPDATE_CONVERSATION_SUCCESS;
  constructor(public payload: Conversation) {}
}

export class UpdateConversationError implements Action {
  readonly type = UPDATE_CONVERSATION_ERROR;
}

export type Actions =
  | ConversationSetInitial
  | ConversationLoad
  | ConversationLoadSuccess
  | ConversationLoadError
  | AddCommentToConversation
  | AddCommentToConversationSuccess
  | AddCommentToConversationError
  | UpdateConversation
  | UpdateConversationSuccess
  | UpdateConversationError;
