import { UntypedFormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function mustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: UntypedFormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function convertDate2String(date: Date, keepTime: boolean = false) {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  if (keepTime) {
    return date.toISOString();
  } else {
    return date.toISOString().split('T')[0];
  }
}

export function convertDateTime2Date(date: string) {
  return date.slice(0, 10);
}

export function hasValue<Type>(value: Type): boolean {
  return value !== null && value !== undefined;
}

export function hasValueAndNotEmpty(value: any) {
  return value !== null && value !== undefined && value !== '';
}

export function isEmptyArray(value: any) {
  return Array.isArray(value) && value?.length == 0;
}

export type RequireOne<T, K extends keyof T> = {
  [X in Exclude<keyof T, K>]?: T[X];
} & {
  [P in K]-?: T[P];
};

export function getDirtyValues(form: any) {
  let dirtyValues = {};
  Object.keys(form.controls).forEach(key => {
    let currentControl = form.controls[key];
    if (currentControl.dirty) {
      if (currentControl.controls) dirtyValues[key] = this.getDirtyValues(currentControl);
      else dirtyValues[key] = currentControl.value;
    }
  });
  for (const [key, value] of Object.entries(dirtyValues)) {
    if (value instanceof Date) dirtyValues[key] = convertDate2String(value, true);
  }
  return dirtyValues;
}

export function getLink(url: string) {
  if (hasValue(url)) {
    if (url.includes('://')) {
      return url;
    } else {
      return `https://` + url;
    }
  }
}

export function htmlToPlainText(html) {
  const tempElement = document.createElement('div');
  tempElement.innerHTML = html;

  // Replace block-level elements with their text content followed by a newline
  tempElement.querySelectorAll('p, ul, ol, li').forEach(element => {
    const text = element.textContent || '';
    element.replaceWith(document.createTextNode(text + '\n'));
  });
  return tempElement.textContent || tempElement.innerText || '';
}
