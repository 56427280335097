import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state/reducers';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { User } from '../types/user.types';
import { UpdateUser } from 'src/app/state/actions/settings.actions';
import { MenteeSetInitial } from 'src/app/state/actions/mentee.actions';
import { MenteeRelationSetInitial } from 'src/app/state/actions/mentee-relations.actions';
import { Observable, catchError, lastValueFrom } from 'rxjs';
import { MentorSetInitial } from 'src/app/state/actions/mentor.actions';
import { CompanySetInitial } from 'src/app/state/actions/company.actions';
import { ConversationSetInitial } from 'src/app/state/actions/conversation.actions';
import { SessionsSetInitial } from 'src/app/state/actions/sessions.actions';

@Injectable()
export class AuthenticationService {
  constructor(private http: HttpClient, private store: Store<AppState>, private router: Router) {}

  loginUrl = environment.apiUrl + '/auth/jwt/create/';

  /**
   * Lets the user log with standard credentials to BE
   * @param username login credential to project's BE
   * @param password login credential to project's BE
   * @returns True if user logged in successfuly or False otherwise
   */
  public async signIn(username: string, password: string): Promise<User | null> {
    try {
      const signInResult = await lastValueFrom(
        this.http.post<{ access: string; refresh: string }>(this.loginUrl, {
          username,
          password,
        })
      );
      if (signInResult && signInResult.access) {
        const now = new Date();
        const tokenExpiration = new Date(now.getTime() + 24 * 60 * 60 * 1000);
        localStorage.setItem('tokenExpiration', tokenExpiration.toString());
        localStorage.setItem('access_token', signInResult.access);
        localStorage.setItem('refresh_token', signInResult.refresh);
        const user = await this.getMe();
        this.store.dispatch(new UpdateUser(user));
        return user;
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  }

  public getToken(): string {
    return localStorage.getItem('access_token');
  }

  isTokenExpired(): boolean {
    const expiration = localStorage.getItem('tokenExpiration');
    if (!expiration) {
      return true;
    }
    const expirationDate = new Date(expiration);
    return expirationDate <= new Date();
  }

  public async refreshToken() {
    if (this.isTokenExpired()) {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) {
        return null;
      }
      try {
        this.http.post<any>(`${environment.apiUrl}/auth/jwt/refresh/`, { refresh: refreshToken }).subscribe({
          next: results => {
            if (results && results.access) {
              const now = new Date();
              const tokenExpiration = new Date(now.getTime() + 24 * 60 * 60 * 1000);
              localStorage.setItem('tokenExpiration', tokenExpiration.toString());
              localStorage.setItem('access_token', results.access);
              return results.access;
            }
          },
          error: (err: any) => {
            console.error('Invalid response from token refresh: ', err.error);
            this.signOut();
            this.router.navigate([`/auth/`]);
          },
          complete: () => {},
        });
      } catch (error) {
        // Handle error from token refresh request
        console.error('Error refreshing token: ', error);
        return null;
      }
    }
    return localStorage.getItem('access_token');
  }

  /**
   * Removes user's traces
   */
  signOut(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('user_role');
    localStorage.removeItem('unreadConv');
    this.store.dispatch(new UpdateUser(null));
    this.store.dispatch(new MenteeSetInitial());
    this.store.dispatch(new MentorSetInitial());
    this.store.dispatch(new CompanySetInitial());
    this.store.dispatch(new MenteeRelationSetInitial());
    this.store.dispatch(new SessionsSetInitial());
    this.store.dispatch(new ConversationSetInitial());
    history.pushState(null, '', location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    setTimeout(() => this.router.navigate(['/auth/']));
  }

  /**
   * Gets user from backend by token in localstorage
   * @returns user if token is valid null otherwise
   */
  public async getMe(): Promise<User> {
    if (localStorage.getItem('access_token') === null) {
      return null;
    }
    try {
      const user = await lastValueFrom(this.getUser());
      if (user.role === 'AD') {
        return null;
      }
      const user_data: User = {
        username: user.username,
        id: user.id,
        email: user.email,
        role: user.role,
      };
      return { ...user_data };
    } catch (err) {
      this.signOut();
      this.router.navigate(['/auth/']);
      return null;
    }
  }

  public getUser(): Observable<User> {
    return this.http.get<User>(environment.apiUrl + '/auth/users/me/').pipe(
      catchError(error => {
        throw error; // Rethrow the error so it can be caught downstream
      })
    );
  }

  public resetPassword(email: string) {
    return this.http.post<HttpResponse<any>>(environment.apiUrl + '/auth/users/reset_password/', { email });
  }

  public resetPasswordConfirm(uid: string, token: string, new_password: string) {
    return this.http.post<HttpResponse<any>>(
      environment.apiUrl + '/auth/users/reset_password_confirm/',
      { uid, token, new_password },
      { observe: 'response' }
    );
  }

  public setPassword(current_password, new_password) {
    return this.http.post<HttpResponse<any>>(environment.apiUrl + '/auth/users/set_password/', {
      current_password,
      new_password,
    });
  }
}
