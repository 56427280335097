<div class="inline-block">
  <input type="file" class="file-input" (change)="onUpload($event)" #fileUpload />
  <div class="file-upload">
    <ng-container *ngIf="!model?.certification_file">
      <button
        pButton
        icon="pi pi-paperclip"
        class="p-button-outlined w-auto mr-2 px-2 text-sm"
        (click)="fileUpload.click()"
        label="Upload new certification"
      ></button
    ></ng-container>
    <ng-container *ngIf="model?.certification_file">
      <button
        pButton
        icon="pi pi-external-link"
        class="p-button-outlined w-auto mr-2 px-2 text-sm"
        (click)="openFile()"
        label="View"
      ></button>
      <button
        pButton
        icon="pi pi-paperclip"
        class="p-button-outlined w-auto px-2 text-sm"
        (click)="fileUpload.click()"
        label="Upload new"
      ></button
    ></ng-container>
  </div>
</div>
