import { Action } from '@ngrx/store';
import { Company } from 'src/app/shared/types/company.types';

export const COMPANY_SET_INITIAL = '[COMPANY] Set initial';
export const COMPANY_LOAD = '[COMPANY] Load company';
export const COMPANY_LOAD_SUCCESS = '[COMPANY] Load company successful';
export const COMPANY_LOAD_ERROR = '[COMPANY] Load company failed';
export const UPDATE_COMPANY = '[COMPANYS] Update company';
export const UPDATE_COMPANY_SUCCESS = '[COMPANYS] Update company successful';
export const UPDATE_COMPANY_ERROR = '[COMPANYS] Update company failed';

export class CompanySetInitial implements Action {
  readonly type = COMPANY_SET_INITIAL;
}

export class CompanyLoad implements Action {
  readonly type = COMPANY_LOAD;
}

export class CompanyLoadSuccess implements Action {
  readonly type = COMPANY_LOAD_SUCCESS;
  constructor(public payload: Company) {}
}

export class CompanyLoadError implements Action {
  readonly type = COMPANY_LOAD_ERROR;
}

export class CompanyUpdate implements Action {
  readonly type = UPDATE_COMPANY;
  constructor(public id: string, public company: Partial<Company>) {}
}

export class CompanyUpdateSuccess implements Action {
  readonly type = UPDATE_COMPANY_SUCCESS;
  constructor(public company: Company) {}
}

export class CompanyUpdateError implements Action {
  readonly type = UPDATE_COMPANY_ERROR;
}

export type Actions =
  | CompanySetInitial
  | CompanyLoad
  | CompanyLoadSuccess
  | CompanyLoadError
  | CompanyUpdate
  | CompanyUpdateSuccess
  | CompanyUpdateError;
