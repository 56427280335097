import { Action } from '@ngrx/store';
import { Value } from 'src/app/shared/types/value-type.types';

export const TEAMSIZES_LOAD = '[TEAMSIZE] Load team-sizes';
export const TEAMSIZES_LOAD_SUCCESS = '[TEAMSIZE] Load team-sizes successful';
export const TEAMSIZES_LOAD_ERROR = '[TEAMSIZE] Load team-sizes failed';

export class TeamSizesLoad implements Action {
  readonly type = TEAMSIZES_LOAD;
}

export class TeamSizesLoadSuccess implements Action {
  readonly type = TEAMSIZES_LOAD_SUCCESS;
  constructor(public payload: Value[]) {}
}

export class TeamSizesLoadError implements Action {
  readonly type = TEAMSIZES_LOAD_ERROR;
}

export type Actions = TeamSizesLoad | TeamSizesLoadSuccess | TeamSizesLoadError;
